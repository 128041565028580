.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
	display: flex;
	justify-content: center;
	align-items: center;
}

.confirmation-box {
	padding: 20px;
	border-radius: 8px;
	text-align: center;
}

.buttons {
	margin-top: 20px;
}

.buttons button {
	margin-right: 10px;
}
