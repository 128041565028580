/* Base styles for large screens */
.flip-container {
	width: 450px;
	height: 600px;
	perspective: 600px;
}

/* Responsive styles for small screens */
@media (max-width: 640px) {
	.flip-container {
		width: 220px;
		height: 300px;
		perspective: 300px;
	}
}

/* Responsive styles for medium screens */
@media (min-width: 641px) and (max-width: 768px) {
	.flip-container {
		width: 420px;
		height: 540px;
		perspective: 540px;
	}
}

.flipper {
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	animation: flipInOut 6s ease infinite; /* Adjust the duration and easing as needed */
}

.front,
.back {
	width: 100%;
	height: 100%;
	position: absolute;
	backface-visibility: hidden;
}

.back {
	transform: rotateY(180deg);
}

@keyframes flipInOut {
	0% {
		transform: rotateY(0deg);
	}
	50% {
		transform: rotateY(190deg);
	}
	100% {
		transform: rotateY(360deg);
	}
}
