@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Noto+Sans+Sora+Sompeng:wght@600&family=Poppins&family=Sora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kdam+Thmor+Pro&family=Libre+Baskerville&family=Noto+Sans+Sora+Sompeng:wght@600&family=Poppins&family=Sora&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Kdam+Thmor+Pro&family=Libre+Baskerville&family=Lilita+One&family=Noto+Sans+Sora+Sompeng:wght@600&family=Playfair+Display&family=Poppins&family=Sora&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Kdam+Thmor+Pro&family=Libre+Baskerville&family=Lilita+One&family=Noto+Sans+Sora+Sompeng:wght@600&family=Playfair+Display&family=Poppins&family=Slabo+27px&family=Sora&display=swap');

.font-libre {
	font-family: 'Libre Baskerville', serif;
}

.font-kdam {
	font-family: 'Kdam Thmor Pro', sans-serif;
}
.logosize {
	font-size: 30px;
	font-weight: 900;
}

.font-playfair {
	font-family: 'Playfair Display', serif;
}

.font-slabo {
	font-family: 'Slabo 27px', serif;
}
